import adminDashboard from './adminDashboard.json';
import chat from './chat.json';
import common from './common.json';
import confirmMentoring from './confirmMentoring.json';
import dateTime from './dateTime.json';
import evaluation from './evaluation.json';
import footer from './footer.json';
import forEnterprises from './forEnterprises.json';
import forgotPassword from './forgotPassword.json';
import header from './header.json';
import home from './home.json';
import login from './login.json';
import mentor from './mentor.json';
import mentoring from './mentoring.json';
import mentors from './mentors.json';
import myAccount from './myAccount.json';
import payment from './payment.json';
import phone from './phone.json';
import register from './register.json';
import reschedule from './reschedule.json';
import resetPassword from './resetPassword.json';
import shareMentorProfile from './shareMentorProfile.json';
import teacher from './teacher.json';
import cookiesPolicy from './cookiesPolicy.json';
import b2b from './b2b.json';
import mentorFeedback from './mentorFeedback.json';
import userDashboard from './userDashboard.json';
import b2cPackage from './b2cPackage.json';
import pricingPage from './pricingPage.json';

export default {
  adminDashboard,
  chat,
  common,
  confirmMentoring,
  dateTime,
  evaluation,
  footer,
  forEnterprises,
  forgotPassword,
  header,
  home,
  login,
  mentor,
  mentoring,
  mentors,
  myAccount,
  payment,
  phone,
  register,
  reschedule,
  resetPassword,
  shareMentorProfile,
  teacher,
  cookiesPolicy,
  b2b,
  mentorFeedback,
  userDashboard,
  b2cPackage,
  pricingPage,
};
